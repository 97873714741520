@let b = block();
@let linkClasses = "p-4 flex flex-col justify-start items-center gap-3 w-full h-full tracking-tight";
@let buttonWrapperClasses = "flex flex-col justify-center items-center p-4 pt-0 button-present";

@if (b.link?.url || b.link?.cached_url) {
    @switch (b.link?.linktype) {
        @case ('url') {
            <a
                [class]="linkClasses"
                [href]="sanitizedLink() | desanitizeSlash"
                [target]="b.link?.target"
                [rel]="b.link?.target === '_blank' ? 'noopener noreferrer' : undefined">
                <ng-container [ngTemplateOutlet]="linkContent"/>
            </a>
            <ng-container [ngTemplateOutlet]="children"/>
        }
        @case ('story') {
            <a
                [class]="linkClasses"
                [routerLink]="b.link?.cached_url | cachedUrlResolver"
                [fragment]="b.link?.anchor"
                [target]="b.link?.target">
                <ng-container [ngTemplateOutlet]="linkContent"/>
            </a>
            <ng-container [ngTemplateOutlet]="children"/>

        }
        @case ('asset') {
            <a
                [class]="linkClasses"
                [href]="sanitizedLink() | desanitizeSlash"
                [target]="b.link?.target">
                <ng-container [ngTemplateOutlet]="linkContent"/>
            </a>
            <ng-container [ngTemplateOutlet]="children"/>
        }
        @case ('email') {
            <a
                [class]="linkClasses"
                [href]="'mailto:' + sanitizedLink() | desanitizeSlash">
                <ng-container [ngTemplateOutlet]="linkContent"/>
            </a>
            <ng-container [ngTemplateOutlet]="children"/>
        }
    }

} @else {
    <div [class]="linkClasses">
        <ng-container [ngTemplateOutlet]="linkContent"/>
        <ng-container [ngTemplateOutlet]="children"/>
    </div>
}

<ng-template #linkContent>
    @if (b.asset.filename) {
        <app-image [block]="{asset:b.asset, priority:b.priority}" class="w-16"/>
    }
    <p class="text-center w-full break-words text-base min-[350px]:text-lg font-extrabold">{{ b.descriptionText }}</p>
</ng-template>

<ng-template #children>
    @for (child of b.children; track child._uid) {
        <div [class]="buttonWrapperClasses">
            <s1-cms-component [blok]="child"/>
        </div>
    }
</ng-template>
