import { ChangeDetectionStrategy, Component, computed, inject, input, ViewEncapsulation } from '@angular/core';
import { AsyncPipe, NgClass, NgOptimizedImage } from '@angular/common';
import { IDecoratedSectionBlock } from './decorated-section.model';
import { TailwindService } from '../../../../service';
import { CmsComponent } from '@seven1/angular-storyblok/components';
import { ISbImageLoadParams } from '@seven1/model';
import { ImageService } from '@seven1/angular-storyblok/image';
import { BreakpointsService } from '../../../../../media';
import { ActivatedRoute } from '@angular/router';
import { KameleoonService } from '@seven1/angular/kameleoon';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
    selector: 'section[app-decorated-section]',
    imports: [CmsComponent, NgOptimizedImage, NgClass, AsyncPipe],
    templateUrl: './decorated-section.component.html',
    styleUrl: './decorated-section.component.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'app-decorated-section',
        '[class.!hidden]': '!visible()',
        '[attr.id]': 'block()?.componentId'
    }
})
export class DecoratedSectionComponent {
    private _route = inject(ActivatedRoute);
    private _kameleoon = inject(KameleoonService);
    private _queryParams = toSignal(this._route.queryParamMap);
    private _images = inject(ImageService);
    private _tailwind = inject(TailwindService);
    breakpoints = inject(BreakpointsService);
    block = input.required<IDecoratedSectionBlock>();
    sizes = input<string>('100vw');

    classes = computed(() => {
        const blok = this.block();
        const bottomChildren = blok?.childrenBottom ? blok?.childrenBottom?.length : 0;
        return [bottomChildren > 0 ? 'pb-32 lg:pb-80' : 'pb-10 md:pb-20', 'flex', 'items-center', ...this._tailwind.getBackgroundColorClasses(blok), ...this._tailwind.getSpacingClasses(blok)];
    });

    visible = computed(() => {
        return this._kameleoon.visible(this._queryParams(), this.block());
    });

    loadParams = computed<ISbImageLoadParams | undefined>(() => {
        const block = this.block();
        let params: ISbImageLoadParams | undefined = undefined;
        if (block.backgroundImage) {
            params = (this._images.extractUrlInfo(block.backgroundImage?.filename) || {}) as ISbImageLoadParams;
        }
        return params;
    });

    loadParamsTablet = computed<ISbImageLoadParams | undefined>(() => {
        const block = this.block();
        let params: ISbImageLoadParams | undefined = undefined;
        if (block.backgroundImageTablet) {
            params = (this._images.extractUrlInfo(block.backgroundImageTablet?.filename) || {}) as ISbImageLoadParams;
        }
        return params;
    });

    loadParamsDesktop = computed<ISbImageLoadParams | undefined>(() => {
        const block = this.block();
        let params: ISbImageLoadParams | undefined = undefined;
        if (block.backgroundImageDesktop) {
            params = (this._images.extractUrlInfo(block.backgroundImageDesktop?.filename) || {}) as ISbImageLoadParams;
        }
        return params;
    });
}
