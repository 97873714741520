{
  "average": {
    "number_of_ratings": "404",
    "exact_average": "4.648514851",
    "rounded_average": "4.65",
    "seal": "silver"
  },
  "snapshot": {
    "info": {
      "fb_count": "0",
      "fb_avg": "0",
      "fb_avg_detail": null,
      "active": true,
      "account_name": "Tierversicherung PETPROTECT",
      "ekomi_certificate": "https://www.ekomi.de/cert/45D187205252EF6",
      "ekomi_certificate_id": "45D187205252EF6",
      "ekomi_certificate_seo": "https://www.ekomi.de/bewertungen-petprotect.html"
    },
    "feedbacks": [
      {
        "transaction_id": "1709830945691",
        "rating": "5",
        "message": "Geht alles sehr einfach, verständlich und schnell.",
        "comment": "",
        "delivered": "2024-03-07 18:03:10"
      },
      {
        "transaction_id": "1709720807934",
        "rating": "5",
        "message": "top",
        "comment": "",
        "delivered": "2024-03-06 11:27:03"
      },
      {
        "transaction_id": "1709713707899",
        "rating": "4",
        "message": "Perönliche Beratung ist mir lieber",
        "comment": "",
        "delivered": "2024-03-06 09:29:21"
      },
      {
        "transaction_id": "1709630614460",
        "rating": "4",
        "message": "Man konnte alle erforderlichen Informationen zur OP-Verischerung direkt auf der Seite finden.",
        "comment": "",
        "delivered": "2024-03-05 10:24:30"
      },
      {
        "transaction_id": "1709627521770",
        "rating": "4",
        "message": "sehr gut und unkompliziert",
        "comment": "",
        "delivered": "2024-03-05 09:32:31"
      },
      {
        "transaction_id": "1709621166476",
        "rating": "4",
        "message": "Immer wieder",
        "comment": "",
        "delivered": "2024-03-05 07:46:26"
      },
      {
        "transaction_id": "1709562582610",
        "rating": "3",
        "message": "Momentan ist das  zu früh  zum bewärten. Aber nette Beratung.",
        "comment": "",
        "delivered": "2024-03-04 15:31:51"
      },
      {
        "transaction_id": "1709562100177",
        "rating": "3",
        "message": "Versicherung hat noch nicht begonnen. Ich kann noch nichts dazu sagen, aber nette Beratung.",
        "comment": "",
        "delivered": "2024-03-04 15:24:16"
      },
      {
        "transaction_id": "1709468266196",
        "rating": "5",
        "message": "Gut",
        "comment": "",
        "delivered": "2024-03-03 13:18:20"
      },
      {
        "transaction_id": "1709376813712",
        "rating": "2",
        "message": "Hatte keine Beratung",
        "comment": "",
        "delivered": "2024-03-02 11:53:56"
      }
    ],
    "done": 1,
    "done_at": 1743693785
  },
  "last_updated_at": "2025-04-03 15:23:05"
}